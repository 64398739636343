




































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Customer } from "@/models/customer";
import ApiError from "@/api/ApiError";

const CustomerStore = namespace('customer');

@Component({})
export default class EditCustomerComponent extends Vue {

  data() {
    return {
      valid: false,
      required(propertyType: any) {
        return (v:any) => v && v.length > 0 || `Bitte Feld ausfüllen`;
      },
      maxLength(propertyType: any, maxLength: number) {
        return (v: any) => v &&  v.length <= maxLength || `${propertyType}  must have max  ${maxLength} characters`;
      },
      minLength(propertyType: any, min: number) {
        return (v: any) => v && v.length >= min || `${propertyType}  must have minimum  ${min} characters`;
      }
    }
  }

  @CustomerStore.Action("createCustomer")
  public createCustomer!:(payload: { customer: Customer }) => Promise<Customer>

  @CustomerStore.Action("updateCustomer")
  public updateCustomer!:(payload: { customer: Customer }) => Promise<Customer>


  public isLoading: boolean = false;

  @Prop({ default: () => new Customer({}) })
  public customer!: Customer;

  public editCustomer?: Customer;

  public closeDialog() {
    this.$emit('closeDialog', false);
  }

  @Watch('customer', { immediate: true })
  public async customerChanged() {
    if (this.customer) {
      this.editCustomer = new Customer(this.customer);
    }
  }

  public async saveCustomer() {
    try {
      if (this.editCustomer!.id) {
        await this.updateCustomer({ customer: this.editCustomer! });
      } else {
        await this.createCustomer({ customer: this.editCustomer! });
      }
      this.$emit('closeDialog', true);
    } catch (e) {
      if (e.code === 409) {
        this.$notify({
          group: 'fahlke',
          title: 'Fehler',
          text: 'Kunde existiert bereits',
          type: 'error'
        });
      } else {
        this.$notify({
          group: 'fahlke',
          title: 'Fehler',
          text: 'Fehler beim Speichern',
          type: 'error'
        });
      }
    }
  }
}

